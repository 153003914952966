import React, { useState } from 'react';

import { A } from '@nuage/ui-components';
import { Link } from 'gatsby';

import lang from '@utils/lang';

import MainLayout from '../components/organisms/main/mainLayout';
import Header from '../components/organisms/main/header';
import Part from '../components/organisms/main/part';

import './404.scss';

const NotFoundPage = () => {
    const { _string } = lang;

    const [menuOpen, setMenuOpen] = useState(false);
    const [subListOpen, setSubListOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleSubList = (e) => {
        e.preventDefault();
        setSubListOpen(!subListOpen);
    };

    return (
        <MainLayout title={_string('notfound_titleTab')} socials={_string('notfound_socials')} classTitle="notfound">
            <div className="primary_block">
                <div className="slidable">
                    <Header
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                        toggleSubList={toggleSubList}
                        setSubListOpen={setSubListOpen}
                        isSubListOpen={subListOpen}
                    />
                </div>
                <Part
                    title={_string('notfound_phrase')}
                    desc=""
                    className="primary_block__content"
                    separator={false}
                >
                    <div className="maintext">{_string('notfound_text')}</div>
                    <div className="secondtext">{_string('notfound_subtext')}</div>
                    <div className="link">
                        <A TypeLink={Link} linkProps={{ to: '/' }}>
                            {_string('notfound_link')}
                        </A>
                    </div>
                </Part>
            </div>
        </MainLayout>
    );
};

export default NotFoundPage;
