import React from 'react';
import PropTypes from 'prop-types';

import './part.scss';

const Part = ({ title, desc, separator, className, anchor, unique, children }) => (
    <div className={`part${className ? ` ${className}` : ''}`} id={anchor}>
        <div className={`part__separator${separator ? ' part__separator--visible' : ''}`} />
        { title && (
            <div className="part__intro">
                {unique
                    ? <h1 className="part__title">{title}</h1>
                    : <h2 className="part__title">{title}</h2>}
                {desc && <p className="part__description">{desc}</p>}
            </div>
        )}
        {children}
    </div>
);

Part.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    desc: PropTypes.node,
    className: PropTypes.string,
    anchor: PropTypes.string,
    separator: PropTypes.bool,
    unique: PropTypes.bool,
};

Part.defaultProps = {
    title: '',
    desc: null,
    anchor: 'no-anchor',
    className: '',
    separator: false,
    unique: false,
};

export default Part;
